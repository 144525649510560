import moment from 'moment';

export const required = (value) => (value || value === 0 ? undefined : 'This field is required');
const maxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
export const maxLength20 = maxLength(20);
export const maxLength255 = maxLength(255);
export const maxLength500 = maxLength(500);
export const number = (value) => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);
export const minValue = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);
export const minValue0 = minValue(0);
export const minValue1 = minValue(1);
export const maxValue = (max) => (value) => (value && value > max ? `Must be equal or less than ${max}` : undefined);
export const maxValue24 = maxValue(24);
export const notEmpty = (value) => (value && value instanceof Array && value.length === 0 ? 'Must be at least one element selected' : undefined);
export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
  ? 'Invalid email address' : undefined);

export const phone = (value) => (value && !/^(\+\d{1}(|-)\d{1,3}(| |-))[0-9()+ -]{6,15}$/.test(value.replace(/\s/g, ''))
  ? 'Invalid phone' : undefined);

export const discount = (value) => (value && !/^\d+(?:\.\d+|)(%?)$/.test(value) ? 'Invalid discount' : undefined);
export const password = (value) => (value && !/^(?=.*\d)(?=.*[aA-zZ])[0-9a-zA-Z]{8,}$/.test(value) ? 'Password must contain 8 characters at least one digit includes' : undefined);
export const postalCode = (value) => (value && !/^([a-zA-Z0-9 -]{3,12})$/.test(value) ? 'Invalid postal code' : undefined);
export const asyncErrorMapper = (err) => {
  const errors = {};
  if (!err.data && err.statusText) {
    return { errorMessage: err.statusText };
  }
  if (err.data && err.data.constraintViolations) {
    Object.values(err.data.constraintViolations).forEach((error) => {
      errors[error.property] = error.message;
    });
  }
  return errors;
};

export const rangeValidate = (min, max, msg) => (value) => (value && (value <= max && value >= min)
  ? undefined
  : msg);

export const birthdayValidator = (date) => {
  if (
    !date
    || !moment(date).isValid()
    || new Date(date).getFullYear() < 1900
    || new Date(date).getTime() > new Date().getTime()
  ) {
    return 'This field is required';
  }
  return undefined;
};

export const ageValidator = (min, max, msg) => rangeValidate(min, max, msg);
