import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  forEach,
  some,
  filter,
  find,
  map,
  join,
  isEqual,
  orderBy,
} from 'lodash';
import { Link } from 'react-router-dom';
import Checkbox from 'material-ui/Checkbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import ListTable from '../components/ListTable';
import AddEntityButton from '../components/AddEntityButton';
import DeleteEntityButton from '../components/DeleteEntityButton';
import EditEntityButton from '../components/EditEntityButton';
import CalendarEntityButton from '../components/CalendarEntityButton';
import MassActionsComponent from '../components/MassActionsComponent';
import EntityAmountDisplay from '../components/EntityAmountDisplay';
import UploadModal from './UploadModal/UploadModal';
import * as instructorsActions from '../actions/instructorsActions';
import { notifySuccess, notifyError } from '../actions/notificationActions';
import Filter from '../components/FilterComponent';
import ActiveButton from '../components/ActiveButton';
import TagList from '../components/TagList';
import NameColumn from '../components/NameColumn';
import '../styles/InstructorList.scss';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import { sortTable } from '../utils/helpers';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import { setInstructorAnchor, goToStep } from '../actions/userGuide';
import { downloadCsvFile } from '../utils/csvHelper';

class InstructorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructors: {},
      searchValues: [],
      massActions: [
        { text: 'Delete', value: 1 },
      ],
      selectedInstructors: [],
      enableRendering: true,
    };
  }

  componentDidMount() {
    const { userGuide, actions } = this.props;
    const { general, currentGuide, step } = userGuide;
    actions.indicatorActions.startLoading('instructors');
    actions.instructors.fetchInstructors().then(() => {
      actions.indicatorActions.stopLoading('instructors');
    });

    if (general && currentGuide === 'instructors' && step === 0) {
      setTimeout(() => actions.goToStepA(1), 250);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userGuide, actions: { setInstructorAnchorA } } = this.props;
    const { instructors: currentInstructors } = nextProps;
    const {
      instructors,
      requestList,
      searchValues: stateSearchValues,
    } = this.state;
    let instructor;
    const searchValues = [];
    forEach(currentInstructors, (i) => {
      if (!instructor) {
        instructor = i;
      }
      const value = {
        value: i,
        text: i.name && i.name.concat(' ', i.surname),
      };
      searchValues.push(value);
    });

    if (!isEqual(searchValues, stateSearchValues)) {
      this.setState({
        searchValues,
      }, () => {
        if (requestList) {
          this.handleSearchAutocomplete(requestList);
        }
      });
    }

    if (!isEqual(instructors, currentInstructors) && !requestList) {
      this.setState({
        instructors: orderBy(currentInstructors, ['weight'], ['asc']),
      });
    }

    if (instructor && !userGuide.instructorAnchor) {
      setInstructorAnchorA(instructor);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.enableRendering;
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.indicatorActions.stopLoading('twoColumns');
  }

  handleSingleCheck = (instructorId) => () => {
    const { selectedInstructors, instructors } = this.state;
    let instructorsChecked = selectedInstructors;
    if (some(selectedInstructors, { id: instructorId })) {
      instructorsChecked = filter(instructorsChecked,
        (instructor) => instructor.id !== instructorId);
      this.refs.multiCheck.state.switched = false;
    } else {
      instructorsChecked.push(find(instructors, { id: instructorId }));
    }
    this.setState({
      selectedInstructors: instructorsChecked,
    });
  }

  handleMultiCheck = (event, isInputChecked) => {
    const { instructors } = this.state;
    let instructorsChecked = [];
    if (isInputChecked) {
      instructorsChecked = instructors;
    }
    this.setState({
      selectedInstructors: instructorsChecked,
    });
  }

  onActiveButtonClick = (instructor) => {
    const { id, active } = instructor;
    const { actions } = this.props;

    actions.indicatorActions.startLoading('instructors');
    actions.instructors.updateInstructor(id, {
      active: !active,
    }).then(() => {
      actions.instructors.fetchInstructorsUsedAmount();
      actions.indicatorActions.stopLoading('instructors');
    }).catch(() => actions.indicatorActions.stopLoading('instructors'));
  };

  onCreateBookingClick = (instructor) => {
    const { id, createBookingPermission } = instructor;
    const { actions } = this.props;

    actions.indicatorActions.startLoading('instructors');
    actions.instructors.updateInstructor(id, {
      createBookingPermission: !createBookingPermission,
    }).then(() => {
      actions.indicatorActions.stopLoading('instructors');
    }).catch(() => actions.indicatorActions.stopLoading('instructors'));
  };

  onEnableEshopClick = (instructor) => {
    const { id, canBeListedInShop, languages } = instructor;
    const { actions } = this.props;

    if ((!canBeListedInShop && languages) || canBeListedInShop) {
      actions.indicatorActions.startLoading('instructors');
      actions.instructors.updateInstructor(id, {
        canBeListedInShop: !canBeListedInShop,
      }).then(() => {
        actions.indicatorActions.stopLoading('instructors');
      }).catch(() => actions.indicatorActions.stopLoading('instructors'));
    } else if (!canBeListedInShop && !languages) {
      actions.notifyError({},
        'Instructor has no languages. Add a language to the instructor to enable them for E-shop.');
    }
  };

  onHeadCoachButtonClick = (instructor) => {
    const { id, role } = instructor;
    const { actions } = this.props;

    actions.indicatorActions.startLoading('instructors');
    actions.instructors.updateInstructor(id, {
      role: role === 'instructor' ? 'headCoach' : 'instructor',
    }).then(() => {
      actions.indicatorActions.stopLoading('instructors');
    }).catch(() => actions.indicatorActions.stopLoading('instructors'));
  };

  handleDelete = (id) => {
    const { actions } = this.props;
    return actions.instructors.deleteInstructor(id).then(() => {
      actions.notifySuccess({}, 'Instructor deleted successfully');
    }).then(() => actions.instructors.fetchInstructorsUsedAmount());
  }

  handleDeleteMassAction = () => {
    const { selectedInstructors } = this.state;
    const { actions } = this.props;
    const undeletedInstructors = [];
    const deletions = map(selectedInstructors,
      (instructor) => actions.instructors.deleteInstructor(instructor.id).catch(() => {
        undeletedInstructors.push(instructor.id);
      }));
    const results = Promise.all(deletions);
    return results.then(() => {
      if (undeletedInstructors.length === 0) {
        actions.notifySuccess({}, 'Instructors deleted successfully');
      }
      actions.instructors.fetchInstructorsUsedAmount();
      this.setState({
        selectedInstructors: [],
      });
      this.refs.multiCheck.state.switched = false;
    });
  }

  instructorsToRows = (instructors) => {
    const { selectedInstructors } = this.state;
    const { openDialog } = this.props;
    const rows = [];

    forEach(instructors, (instructor) => {
      const row = [];
      let phoneNumbers = '';
      if (instructor.phoneNumbers) {
        phoneNumbers = join(instructor.phoneNumbers, ', ');
      }

      row[0] = (
        <div>
          <span style={{ position: 'relative', right: '38px' }} id="drag-anchor" />
          <Checkbox
            checked={some(selectedInstructors, { id: instructor.id })}
            onCheck={this.handleSingleCheck(instructor.id)}
          />
        </div>
      );

      row[1] = (
        <ActiveButton
          id={instructor.id}
          status={instructor.active}
          onClick={() => this.onActiveButtonClick(instructor)}
          anchor="active-anchor"
        />
      );

      row[2] = (
        <ActiveButton
          id={instructor.id}
          status={instructor.role === 'headCoach'}
          onClick={() => this.onHeadCoachButtonClick(instructor)}
          anchor="headcoach-anchor"
        />
      );
      row[3] = (
        <ActiveButton
          id={instructor.id}
          status={instructor.createBookingPermission}
          onClick={() => this.onCreateBookingClick(instructor)}
        />
      );

      row[4] = (
        <ActiveButton
          id={instructor.id}
          status={instructor.canBeListedInShop}
          onClick={() => this.onEnableEshopClick(instructor)}
        />
      );

      row[5] = (
        <NameColumn
          route="instructor"
          entity={instructor}
        />
      );

      row[6] = '';
      if (instructor.surname) {
        row[6] = instructor.surname;
      }

      row[7] = '';
      if (instructor.activities !== null) {
        const tags = instructor.activities;
        row[7] = (
          <TagList tags={tags} />
        );
      }

      row[8] = '';
      if (instructor.languages) {
        row[8] = (
          <div>
            {map(instructor.languages, (item, index) => {
              if (item.code) {
                return (
                  <span key={index}>
                    {item.code.toUpperCase()}
                    {' '}
                    /
                    {' '}
                    {item.level}
                    {' '}
                  </span>
                );
              }
              return null;
            })}
          </div>
        );
      }

      row[9] = [];
      if (instructor.email) {
        row[9] = instructor.email;
      }

      row[10] = (
        <span>{phoneNumbers}</span>
      );

      row[11] = (
        <div className="list-table__column-content--inline">
          <span id="edit-anchor" style={{ position: 'relative', bottom: '20px', left: '10px' }} />
          <span id="availability-anchor" style={{ position: 'relative', bottom: '20px', left: '65px' }} />
          <Link to={{ pathname: `/availability/${instructor.id}` }}><CalendarEntityButton /></Link>
          <Link to={{ pathname: `/instructor/${instructor.id}` }}><EditEntityButton /></Link>
          <a>
            <DeleteEntityButton
              id={instructor.id}
              handleDelete={() => openDialog(() => this.handleDelete(instructor.id))}
            />
          </a>
        </div>
      );

      row[12] = instructor.id;
      rows.push(row);
    });
    return rows;
  }

  handleSearchAutocomplete = (requestList, input) => {
    const { searchValues } = this.state;
    const displayInstructors = [];
    this.setState({
      requestList,
    });

    if (input) {
      forEach((requestList), (listItem) => {
        displayInstructors.push(searchValues[listItem.value.key].value);
      });
    } else {
      forEach(searchValues, (instructor) => {
        displayInstructors.push(instructor.value);
      });
    }
    this.setState({
      instructors: displayInstructors,
    });
  }

  handleSortTable = (columnIndex, asc) => {
    const { instructors } = this.state;
    const columns = {
      1: {
        name: ['active', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      2: {
        name: ['role', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      3: {
        name: ['createBookingPermission', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      4: {
        name: ['canBeListedInShop', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      5: {
        name: ['name', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      6: {
        name: ['surname', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      7: {
        name: ['activities', 'weight'], dependency: 'activity', level: 1, orderType: 'normal', dataArray: 'key',
      },
      8: {
        name: ['languages', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: 'code',
      },
      9: {
        name: ['email', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      10: {
        name: ['phoneNumbers', 'weight'], dependency: null, level: 1, orderType: 'normal', dataArray: 'empty',
      },
    };
    const sortedList = sortTable(instructors, columns, columnIndex, asc);
    this.setState({
      instructors: sortedList,
    });
  }

  handleDrag = (idOnDrag, idOnDrop) => {
    const { actions } = this.props;
    this.setState({
      enableRendering: false,
    });
    const { instructors } = this.state;
    const weightNew = find(instructors, { id: idOnDrop }).weight;
    actions.instructors.updateInstructor(idOnDrag, { weight: weightNew }).then(() => {
      actions.instructors.fetchSortedInstructors().then((res) => {
        this.setState({
          instructors: orderBy(res.payload.result, ['weight'], ['asc']),
          enableRendering: true,
        });
      });
    });
  }

  handleUploadModalState = () => {
    const { uploadModalOpen } = this.state;
    this.setState({ uploadModalOpen: !uploadModalOpen });
  }

  handleExportClick = () => {
    const { actions: { instructors: { instructorsExport } } } = this.props;
    instructorsExport().then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'instructors.csv');
    });
  };

  render() {
    const {
      uploadModalOpen,
      massActions,
      searchValues,
      instructors,
    } = this.state;
    const { refreshIndicator, openDialog, actions } = this.props;

    return (
      <div className="instructor-list">
        <div className="instructor-list__row-wrapper">
          <div>
            <AddEntityButton
              link
              label="ADD INSTRUCTOR"
              path="/instructor/add"
            />
            <AddEntityButton
              label="UPLOAD"
              className="add-entity-button__theme--green"
              id="upload-anchor"
              onClick={this.handleUploadModalState}
            />
            <AddEntityButton
              label="EXPORT"
              className="add-entity-button__theme--pink"
              onClick={this.handleExportClick}
            />
            <MassActionsComponent
              massActions={massActions}
              detectMassAction={() => {}}
              handleMassAction={() => openDialog(() => this.handleDeleteMassAction())}
            />
            <Filter
              destination="Instructors"
              dataSource={searchValues}
              onUpdateInput={this.handleSearchAutocomplete}
            />
          </div>
          <EntityAmountDisplay entity="instructors" />
        </div>
        <UploadModal
          open={uploadModalOpen}
          handleUploadModalState={this.handleUploadModalState}
          uploadFn={actions.instructors.instructorsImport}
          onSuccessCallback={actions.instructors.fetchInstructors}
          templateName="instructors.csv"
          templateLocation="https://s3-eu-west-1.amazonaws.com/skicms-csv/instructors.csv"
        />
        <ListTable
          dest="instructor"
          names={['checkbox', 'active', 'active', 'active', 'active', 'name', 'surname', 'activity', 'language', 'email', 'phone', 'icons']}
          className={!refreshIndicator.instructors.loaded ? 'indicator-hidden' : ''}
          header={[
            <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
              <Checkbox ref="multiCheck" onCheck={this.handleMultiCheck} />
            </MuiThemeProvider>,
            'Active',
            'Head Coach',
            'Create Booking',
            'E-shop',
            'Name',
            'Surname',
            'Activity',
            'Language',
            'Email',
            'Phone number',
            ' ',
          ]}
          rows={this.instructorsToRows(instructors)}
          handleSort={this.handleSortTable}
          draggable
          onDrag={this.handleDrag}
        />
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.instructors.loaded ? 'hide' : 'loading'}
          className={refreshIndicator.instructors.loaded ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
      </div>
    );
  }
}

InstructorList.propTypes = {
  actions: PropTypes.shape({
    goToStepA: PropTypes.func,
    setInstructorAnchorA: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyError: PropTypes.func,
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
    instructors: PropTypes.shape({
      fetchInstructors: PropTypes.func,
      updateInstructor: PropTypes.func,
      deleteInstructor: PropTypes.func,
      fetchInstructorsUsedAmount: PropTypes.func,
      fetchSortedInstructors: PropTypes.func,
      instructorsExport: PropTypes.func,
      instructorsImport: PropTypes.func,
    }),
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    instructorAnchor: PropTypes.any,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.object,
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    instructors: PropTypes.shape({
      loaded: PropTypes.bool,
    }),
  }),
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    instructors: bindActionCreators(instructorsActions, dispatch),
    notifySuccess: bindActionCreators(notifySuccess, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
    setInstructorAnchorA: bindActionCreators(setInstructorAnchor, dispatch),
    goToStepA: bindActionCreators(goToStep, dispatch),
    notifyError: bindActionCreators(notifyError, dispatch),
  },
});

const mapStateToProps = (state) => ({
  instructors: state.entities.instructor,
  userGuide: state.userGuide,
  refreshIndicator: state.refreshIndicator,
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorList);
