import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  map,
  forEach,
  remove,
} from 'lodash';
import moment from 'moment';
import RefreshIndicator from 'material-ui/RefreshIndicator';

import invokeURL from '../../config/api-config';
import Component from '../components/Component';
import MassActionsComponent from '../components/MassActionsComponent';
import EntityAmountDisplay from '../components/EntityAmountDisplay';
import BookingForm from './BookingForm';

import DataTable from './Table/DataTable';
import bookingListColumns from './TableColumns/BookingListColumns';
import { fetchBooking, exportBookings, fetchBookingsForList } from '../actions/bookingActions';
import { fetchClients } from '../actions/clientsActions';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import { showDrawer } from '../actions/drawerActions';
import { notifyError } from '../actions/notificationActions';
import { fetchInstructors } from '../actions/instructorsActions';
import { fetchGroupByGuid } from '../actions/groupsActions';
import { fetchPartnerManagers } from '../actions/partnerManagerActions';
import { fetchProducts } from '../actions/productsActions';

import '../styles/BookingList.scss';

import { pageSizes } from '../utils/helpers';
import { downloadCsvFile } from '../utils/csvHelper';
import { getGroupPromises } from '../utils/formHelpers';

class BookingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 20,
      pageNumber: 0,
      loading: true,
      formattedData: [],
      selectedBookings: [],
      massActions: [
        // { text: 'Download', value: 0 },
        { text: 'Export', value: 0 },
      ],
      selectedMassAction: 0,
      orderBy: {
        column: 'bid',
        direction: 'DESC',
      },
      selectAll: false,
      filterValue: '',
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    const payload = {
      orderBy: {
        column: 'bid',
        direction: 'DESC',
      },
    };
    actions.startLoading('bookings');
    actions.fetchClients();
    actions.fetchInstructors();
    actions.fetchPartnerManagers();
    actions.fetchProducts();
    actions.fetchBookingsForList(1, 20, payload).then((res) => {
      actions.stopLoading('bookings');
      const { result } = res.payload;
      this.fetchData(undefined, undefined, result);
    });
  }

  fetchData = (
    pageNumber = undefined,
    pageSize = undefined,
    bookings = undefined,
    filter = undefined,
  ) => {
    const { actions } = this.props;
    const { orderBy, pageNumber: statePageNumber, pageSize: statePageSize } = this.state;
    this.setState({
      loading: true,
    });
    let updatedPageNumber = pageNumber;
    let updatedPageSize = pageSize;
    if (!updatedPageNumber) {
      updatedPageNumber = statePageNumber === 0 ? 1 : statePageNumber;
    }
    if (!updatedPageSize) {
      updatedPageSize = statePageSize;
    }
    const payload = {
      filter: filter || '',
      orderBy,
    };
    if (bookings) {
      this.formatDataForTable(undefined, bookings.data);
      this.setState({
        loading: false,
        total: bookings.count,
      });
    } else {
      let results = {};
      actions.fetchBookingsForList(updatedPageNumber, updatedPageSize, payload).then((res) => {
        results = res.payload.result;
        this.formatDataForTable(undefined, results.data);
        this.setState({
          total: results.count,
          loading: false,
        });
      });
    }
  };

  formatLessonColumn = (booking) => {
    const lessonsArray = [];
    forEach(booking.lessonBlocks, (lessonBlock) => {
      if (lessonBlock.type === 'individual') {
        forEach(lessonBlock.lessons, (lesson) => {
          const instructor = lesson.instructor
            ? `${lesson.instructor.name} ${lesson.instructor.surname}` : undefined;
          lessonsArray.push({
            instructorFullName: instructor || 'No instructor',
            productName: lesson.product.name,
            productSpeciality: lesson.speciality,
            productLevel: lesson.product.level,
            productActivity: lesson.activity && lesson.activity.name,
          });
        });
      } else {
        forEach(lessonBlock.lessons, (lesson) => {
          if (lesson && lesson.groups && lesson.groups[0]) {
            lessonsArray.push({
              instructorFullName: 'Group lesson',
              productActivity: lesson.activity.name,
              productName: lesson.groups[0].name,
              productLevel: lesson.groups[0].level,
              productSpeciality: lesson.speciality,
            });
          } else {
            lessonsArray.push({
              instructorFullName: 'Group lesson',
              productName: 'Group was deleted',
            });
          }
        });
      }
    });
    return lessonsArray;
  };

  formatDataForTable = (filterValue = undefined, bookings) => {
    const formattedArray = [];
    forEach(bookings, (booking) => {
      if (booking.lessonBlocks[0] && booking.lessonBlocks[0].lessons
        && booking.lessonBlocks[0].lessons.length > 0) {
        let dateFrom = booking.lessonBlocks[0].lessons[0].timeFrom;
        let dateTo = booking.lessonBlocks[0].lessons[0].timeTo;
        const price = booking.total ? {
          currency: booking.currency,
          total: booking.total,
        } : '-';
        const partnerManager = booking.partnerManager ? `${booking.partnerManager.name} ${booking.partnerManager.surname}` : '-';
        forEach(booking.lessonBlocks, (lessonBlock) => {
          forEach(lessonBlock.lessons, (lesson) => {
            if (moment(lesson.timeFrom).isBefore(moment(dateFrom))) {
              dateFrom = lesson.timeFrom;
            }
            if (moment(lesson.timeTo).isAfter(moment(dateTo))) {
              dateTo = lesson.timeTo;
            }
          });
        });
        const clientName = booking.client ? `${booking.client.name} ${booking.client.surname}` : '-';
        const searchingValue = new RegExp(filterValue, 'i');
        if ((filterValue
            && (clientName.match(searchingValue) || partnerManager.match(searchingValue) || `B-${booking.bid}`.match(searchingValue)))
          || !filterValue) {
          formattedArray.push({
            id: booking.id,
            bid: booking.bid,
            dateFrom,
            dateTo,
            client: {
              id: booking.client,
              name: clientName,
            },
            price,
            partnerManager,
            status: booking.status,
            lesson: this.formatLessonColumn(booking),
          });
        }
      }
    });
    this.setState({
      formattedData: formattedArray,
    });
  };

  calculatePageSizeOptions = () => {
    const { total, pageNumber, pageSize } = this.state;
    const updatedPageSizes = [];
    for (let i = 0; i < pageSizes.length; i += 1) {
      if (Math.ceil(total / pageSize) === pageNumber) {
        if (total < pageSize) {
          updatedPageSizes.push(total);
          break;
        } if (total - ((pageNumber - 1) * pageSize) >= pageSizes[i]) {
          updatedPageSizes.push(pageSizes[i]);
        } else {
          updatedPageSizes.push(total - ((pageNumber - 1) * pageSize));
          break;
        }
      } else if (total > pageSizes[i]) {
        updatedPageSizes.push(pageSizes[i]);
      } else {
        updatedPageSizes.push(total);
        break;
      }
    }
    return updatedPageSizes;
  };

  calculatePageSize = () => {
    const { pageSize, pageNumber, total } = this.state;
    if (total < pageSize) {
      return total;
    }
    if (total - (pageNumber * pageSize) >= 0) {
      return pageSize;
    }
    return total - ((pageNumber - 1) * pageSize);
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize,
      pageNumber: 1,
    });
    this.fetchData(1, pageSize);
  };

  onPageChange = (pageNumber) => {
    this.setState({
      pageNumber,
    });
    this.fetchData(pageNumber);
  };

  handleRowsSelection = (value) => {
    const { selectedBookings, selectAll } = this.state;
    let bookingsAfterSelect = selectedBookings;
    if (value === '*') {
      bookingsAfterSelect = [];
      this.setState({
        selectAll: !selectAll,
      });
    } else if (bookingsAfterSelect.includes(value)) {
      bookingsAfterSelect = remove(bookingsAfterSelect, (val) => val !== value);
    } else {
      bookingsAfterSelect.push(value);
    }
    this.setState({
      selectedBookings: bookingsAfterSelect,
    });
  };

  fromBooking = (id, bookingPage) => {
    const { actions, user, openDialog } = this.props;
    const { filterValue } = this.state;

    return new Promise((resolve) => {
      this.resolveEntity('booking', id, actions.fetchBooking).then((booking) => {
        const { lessonBlocks, bookingServices, sendClientInvoiceToManager } = booking;
        const promises = getGroupPromises(lessonBlocks, actions.fetchGroupByGuid);
        const mappedLessonBlocks = lessonBlocks.map((block) => this.resolveLessonBlock(block));

        Promise.all(promises).then(() => {
          resolve({
            edit: true,
            currency: user.account.currency,
            initialValues: {
              ...booking,
              bookingServices: map(bookingServices, (bookingService) => ({
                ...bookingService,
                discount: bookingService.discountType === 'percent' ? `${bookingService.discount}%` : bookingService.discount,
              })),
              lessonBlocks: mappedLessonBlocks,
            },
            openDialog,
            sendClientInvoiceToManager,
            bookingPage,
            refreshBookingTable: () => this.fetchData(undefined, undefined, undefined, filterValue),
          });
        });
      });
    });
  };

  handleEditButton = (bookingId) => {
    const prepopulationStrategy = () => this.fromBooking(bookingId, true);
    this.openBookingForm(prepopulationStrategy);
  };

  openBookingForm = (prepopulationStrategy) => {
    const { actions } = this.props;
    actions.startLoading('drawer');
    prepopulationStrategy().then((values) => {
      actions.showDrawer(BookingForm, values);
      actions.stopLoading('drawer');
    });
  };

  handleInvoiceButton = (id) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    link.href = [invokeURL.invokeUrl, `/bookingInvoice/${id}`].join('');
    link.target = '_blank';
    link.download = 'file.pdf';
    link.click();
    document.body.removeChild(link);
  };

  setMassActionType = (type) => {
    this.setState({
      selectedMassAction: parseInt(type, 10),
    });
  };

  handleAsyncInvoice = (id, ct) => {
    setTimeout(() => {
      this.handleInvoiceButton(id);
    }, 1000 * ct);
  };

  handleExportBookings = () => {
    const {
      selectedBookings, selectAll, orderBy, filterValue,
    } = this.state;
    const { actions } = this.props;
    const payload = {
      filter: filterValue,
      orderBy,
    };
    if (selectAll) {
      if (selectedBookings.length > 0) {
        payload.excluded = selectedBookings;
      }
    } else if (selectedBookings.length > 0) {
      payload.ids = selectedBookings;
    } else {
      return actions.notifyError(
        {},
        'There are no bookings selected to export. Select at least one booking to export',
      );
    }
    return actions.exportBookings(payload).then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'bookings.csv');
    });
  };

  handleMassActionSubmit = () => {
    const { selectedMassAction } = this.state;
    // Functionality for download mass action
    //
    // if (selectedMassAction === 0) {
    //   forEach(selectedBookings, (booking) => {
    //     this.handleAsyncInvoice(booking);
    //   });
    //   this.setState({
    //     selectedBookings: [],
    //   });
    // } else
    if (selectedMassAction === 0) {
      this.handleExportBookings();
    }
  };

  getFilterValue = () => {
    const filterValue = document.getElementById('filter').value;
    this.setState({
      filterValue,
      selectedBookings: [],
      pageNumber: 0,
      loading: true,
    });
    this.fetchData(undefined, undefined, undefined, filterValue);
  };

  setSorting = (id) => {
    if (id === 'lesson' || id === 'id') {
      return;
    }
    const { orderBy } = this.state;
    let updatedId = id;
    switch (id) {
      case 'dateFrom':
        updatedId = 'lesson.timeFrom';
        break;
      case 'client':
        updatedId = 'client.name';
        break;
      case 'price':
        updatedId = 'total';
        break;
      default:
        break;
    }
    if (orderBy.column === updatedId) {
      if (orderBy.direction === 'desc') {
        this.setState({
          orderBy: {
            column: updatedId,
            direction: 'asc',
          },
        }, () => this.fetchData());
      } else {
        this.setState({
          orderBy: {
            column: updatedId,
            direction: 'desc',
          },
        }, () => this.fetchData());
      }
    } else {
      this.setState({
        orderBy: {
          column: updatedId,
          direction: 'asc',
        },
      }, () => this.fetchData());
    }
    this.setState({
      pageNumber: 1,
      selectedBookings: [],
    });
  };

  render() {
    const { refreshIndicator } = this.props;
    const {
      pageSize,
      total,
      pageNumber,
      loading,
      selectedBookings,
      formattedData,
      massActions,
      selectAll,
    } = this.state;
    let timeout = null;

    return (
      <div className="booking-list">
        <div className="instructor-list__row-wrapper">
          <div>
            <MassActionsComponent
              massActions={massActions}
              detectMassAction={this.setMassActionType}
              handleMassAction={this.handleMassActionSubmit}
            />
            <span className="filter">
              <label>Filter:</label>
              <input
                id="filter"
                onChange={() => {
                  clearTimeout(timeout);
                  timeout = setTimeout(() => this.getFilterValue(), 500);
                }}
                placeholder="Booking number, client name, partner..."
              />
            </span>
          </div>
          <EntityAmountDisplay entity="bookings" hide={!refreshIndicator.bookings.loaded} />
        </div>
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.bookings.loaded ? 'hide' : 'loading'}
          className={refreshIndicator.bookings.loaded ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
        <div className="booking-table">
          {refreshIndicator.bookings.loaded
          && (
            <DataTable
              loading={loading}
              noDataText="No data found"
              page={pageNumber - 1 < 0 ? 0 : pageNumber - 1}
              pageSizeOptions={this.calculatePageSizeOptions()}
              pageSize={this.calculatePageSize()}
              onPageSizeChange={this.onPageSizeChange}
              pages={Math.ceil(total / pageSize)}
              columns={bookingListColumns(
                this.handleRowsSelection,
                selectedBookings,
                selectAll,
                this.handleEditButton,
                this.handleInvoiceButton,
              )}
              showPagination
              data={formattedData}
              manual
              getTheadThProps={(state, rowInfo, column) => ({
                onClick: () => {
                  if (column.id === 'partnerManager') {
                    // setting sort only by manager's surname
                    this.setSorting('partnermanager.surname');
                  } else this.setSorting(column.id);
                },
              })}
              // Remove overflow: hidden (css) in order to show popup when relevant
              getTdProps={(state, row, column) => ({
                ...column.id === 'lesson' && row?.original?.lesson?.length > 1 ? { style: { overflow: 'visible' } } : {},
              })}
              onPageChange={(pageIndex) => this.onPageChange(pageIndex + 1)}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchBooking,
    fetchBookingsForList,
    fetchClients,
    exportBookings,
    startLoading,
    stopLoading,
    showDrawer,
    notifyError,
    fetchInstructors,
    fetchGroupByGuid,
    fetchPartnerManagers,
    fetchProducts,
  }, dispatch),
});

BookingList.propTypes = {
  actions: PropTypes.shape({
    fetchBooking: PropTypes.func,
    fetchBookingsForList: PropTypes.func,
    fetchClients: PropTypes.func,
    exportBookings: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
    showDrawer: PropTypes.func,
    notifyError: PropTypes.func,
    fetchInstructors: PropTypes.func,
    fetchGroupByGuid: PropTypes.func,
    fetchPartnerManagers: PropTypes.func,
    fetchProducts: PropTypes.func,
  }),
  user: PropTypes.shape({
    account: PropTypes.shape({
      currency: PropTypes.string,
    }),
  }),
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    bookings: PropTypes.shape({
      loaded: PropTypes.bool,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
