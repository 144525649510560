// -------------------------- *** Modules *** ----------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import moment from 'moment';
import _ from 'lodash';
import Popover from 'material-ui/Popover';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import Dialog from '../components/dialog/Dialog';
import Timeline from './Timeline';
// -------------------------- *** Containers *** -------------------------------
import ProfileNav from './ProfileNav';
import GroupForm from './GroupForm';
import GroupView from './GroupView';
import BookingForm from './BookingForm';
import Filter from './Filter';
import ExportFilterWizard from './ExportFilterWizard';
// -------------------------- *** Components *** -------------------------------
import Component from '../components/Component';
import TodaysCalendarButton from '../components/TodaysCalendarButton';
import AddEntityButton from '../components/AddEntityButton';
import CalendarItemPopover from '../components/CalendarItemPopover';
import GuideActivationPopup from '../components/GuideActivationPopup';
import GuideFinishPopup from '../components/GuideFinishPopup';
import EntityAmountDisplay from '../components/EntityAmountDisplay';
// ---------------------------- *** Actions *** --------------------------------
import { fetchInstructors } from '../actions/instructorsActions';
import {
  fetchEvents,
  updateEvent,
  removeEvent,
  patchEvent,
  clear,
} from '../actions/eventActions';
import { showDrawer, hideDrawer } from '../actions/drawerActions';
import { fetchBooking, fetchBookingsUsedAmount } from '../actions/bookingActions';
import { fetchGroupsForBookings, fetchGroupPrice, fetchGroupByGuid } from '../actions/groupsActions';

import { removeFilteredProductTimes } from '../actions/productsActions';
import { fetchClients } from '../actions/clientsActions';
import { fetchPartnerManagers } from '../actions/partnerManagerActions';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import {
  activateGuide,
  deactivateGuide,
  removeTimelineBoundsChange,
  removeCalendarScrollIntoView,
  goToStep,
} from '../actions/userGuide';
// --------------------------- *** Stylesheets *** -----------------------------
import '../styles/Calendar.scss';

class CalendarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInstructors: [],
      instructors: {},
      dialogOpen: false,
      anchorEl: undefined,
      popoverOpen: false,
      popoverProps: {},
      guideActivationPopup: false,
      guideFinishPopup: false,
      loading: true,
      updateFilter: false,
    };
  }

  componentDidMount() {
    const { actions } = this.props;

    Promise.all([
      actions.fetchInstructors(),
      actions.fetchEvents({}),
      actions.fetchPartnerManagers(),
      actions.fetchClients(),
      actions.fetchGroupsForBookings(),
    ]).then(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const userGuide = urlParams.get('user-guide');

      if (userGuide && userGuide === 'active') {
        this.setState({ guideActivationPopup: true });
      }
      this.setState({ loading: false });

      this.onTodaysCalendarButtonClick();
    }).catch(() => {
      this.setState({ loading: false });
      this.onTodaysCalendarButtonClick();
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { instructors, actions, userGuide } = nextProps;
    const { userGuide: prevUserGuide } = this.props;
    const { filteredInstructors, waitForResponse } = this.state;

    if (filteredInstructors && filteredInstructors.length > 0) {
      this.filterInstructors(instructors);
    } else if (!waitForResponse) {
      this.setState({
        instructors: _.orderBy(instructors, ['weight'], ['asc']),
      });
    }

    if (userGuide && userGuide.triggerTimelineBoundsChange) {
      const { triggerTimelineBoundsChange: { timeFrom, timeTo, group } } = userGuide;

      this.updateCalendarView(timeFrom, timeTo, actions.removeTimelineBoundsChange);
      this.scrollIntoView(group);
    }

    if (userGuide && userGuide.calendarScrollIntoView) {
      this.scrollIntoView(userGuide.calendarScrollIntoView);
    }

    if (userGuide && userGuide.triggerGroupFormOpen && !prevUserGuide.triggerGroupFormOpen) {
      this.onAddGroupButtonClick();
    }

    if (userGuide && userGuide.triggerBookingFormOpen && !prevUserGuide.triggerBookingFormOpen) {
      this.onAddBookingButtonClick();
    }

    if (userGuide && userGuide.triggerCreateOffersOpen && !prevUserGuide.triggerCreateOffersOpen) {
      this.handleExportDialogState();
    }

    if (userGuide && !userGuide.triggerCreateOffersOpen && prevUserGuide.triggerCreateOffersOpen) {
      this.handleExportDialogState(true);
      actions.deactivateGuide();
    }

    if (userGuide && !userGuide.general && prevUserGuide.general && prevUserGuide.currentGuide === 'createOffer' && prevUserGuide.step === 13) {
      this.setState({ guideFinishPopup: true });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.hideDrawer();
    actions.clear();
  }

  filterInstructors = (instructors) => {
    const { filteredInstructors: stateFilteredInstructors } = this.state;
    const filteredInstructors = _.values(instructors).filter((instructor) => typeof _.find(stateFilteredInstructors, (item) => item === instructor.id) !== 'undefined');
    this.setState({
      instructors: _.orderBy(filteredInstructors, ['weight'], ['asc']),
    });
  }

  onTodaysCalendarButtonClick = () => {
    const timeFrom = moment().add(-12, 'hour');
    const timeTo = moment().add(12, 'hour');

    this.updateCalendarView(timeFrom, timeTo);
  }

  updateCalendarView = (timeFrom, timeTo, callbackFn) => {
    let tFrom = timeFrom;
    let tTo = timeTo;

    if (typeof timeFrom === 'string') {
      tFrom = moment(timeFrom);
    }

    if (typeof timeTo === 'string') {
      tTo = moment(timeTo);
    }

    this.timeline.updateScrollCanvas(tFrom.valueOf(), tTo.valueOf());

    if (callbackFn) {
      callbackFn();
    }
  };

  scrollIntoView = (name) => {
    const { actions } = this.props;
    document.getElementById(name).scrollIntoView(true);
    actions.removeCalendarScrollIntoView();
  };

  onItemUpdate = (item, newItem) => {
    const { actions, events } = this.props;
    const id = newItem.id.split('-')[1];
    let body = {
      id,
      timeFrom: new Date(newItem.timeFrom),
      timeTo: new Date(newItem.timeTo),
      instructor: newItem.instructor,
      eventType: newItem.eventType,
    };
    if (newItem.eventType === 'group') {
      body = {
        ...body,
        prevInstructor: item.instructor,
      };
    }
    actions.updateEvent(id, body).then(() => {
      let newId = newItem.id.split('-');

      newId[2] = newItem.instructor;
      newId = newId.join('-');

      actions.fetchBookingsUsedAmount();

      if (newItem.eventType !== 'group') return;
      if (item.id === newId) return;

      if (newItem.instructor === 0) {
        let itemId = item.id.split('-');
        delete itemId[2];
        itemId = itemId.join('-');
        _.forEach(events, (event) => {
          if (event.id.indexOf(itemId) > -1) {
            actions.removeEvent(event.id);
          }
        });
        actions.patchEvent(newId, newItem);
      } else {
        actions.removeEvent(item.id);
        actions.patchEvent(newId, item);
      }
    });
  }

  fromFilter = (values) => {
    const { user } = this.props;
    const currency = _.get(user, 'account.currency', '');

    return new Promise((resolve) => {
      resolve({
        edit: false,
        currency,
        initialValues: {
          prepaymentType: null,
          fullpaymentType: null,
          partnerManagerCommission: false,
          lessonBlocks: [
            {
              edit: false,
              type: values.type || 'individual',
              lessons: [{
                ...this.getPrepopulatedLesson(),
                paid: false,
                buyers: [
                  { level: null },
                ],
              }],
            },
          ],
        },
        setUpdateFilter: this.setUpdateFilter(true),
      });
    });
  }

  onAddBookingButtonClick = () => {
    const { actions, userGuide } = this.props;
    const { general, currentGuide, step } = userGuide;

    this.openDrawerForm(() => this.fromFilter(JSON.parse(localStorage.filter || '{}')), BookingForm);

    if (general && currentGuide === 'createBooking' && step === 1) {
      setTimeout(() => actions.goToStep(2), 500);
    }
  }

  onAddGroupButtonClick = () => {
    const { actions, userGuide: { general, currentGuide, step } } = this.props;
    actions.showDrawer(GroupForm, {
      updateCalendarView: this.updateCalendarView,
      setUpdateFilter: this.setUpdateFilter(true),
    });

    if (general && currentGuide === 'createGroup' && step === 1) {
      setTimeout(() => actions.goToStep(2), 500);
    }
  }

  onFilterUpdate = (values) => {
    const { instructors } = values;
    this.setState({
      filteredInstructors: typeof instructors === 'undefined' ? [] : instructors,
      instructors: _.orderBy(typeof instructors === 'undefined' ? [] : instructors, ['weight'], ['asc']),
    });
  }

  onInstructorFilterUpdate = (values) => {
    const { instructors } = values;
    this.setState({
      waitForResponse: instructors.length === 0,
      filteredInstructors: typeof instructors === 'undefined' ? [] : instructors,
      instructors: _.orderBy(typeof instructors === 'undefined' ? [] : instructors, ['weight'], ['asc']),
    });
  }

  onGroupOpen = (event) => {
    const { actions, openDialog, group } = this.props;
    const id = event.id.split('-')[1];
    actions.showDrawer(GroupView, {
      group: {
        ...group[id] ? group[id] : {},
        id,
        guid: event.guid,
        name: event.name,
        level: event.level,
      },
      onClose: this.onGroupViewClose,
      openDialog,
    });
  }

  onGroupViewClose = () => {
    const { actions } = this.props;
    const filter = localStorage.getItem('filter') || {};

    actions.fetchEvents(JSON.parse(filter));
  }

  onInstructorClick = (id) => {
    if (id === 0) {
      return;
    }
    window.open(
      `/instructor/${id}/overview`,
      '_blank',
    );
  };

  onItemContextMenu = (id) => {
    const { events } = this.props;
    const type = id.split('-')[0];

    if (type === 'L') {
      const item = _.find(events, { id });
      const domNode = document.getElementById(id);
      this.handlePopoverState(domNode);
      this.setState({ popoverProps: item });
    }
  };

  onItemDoubleClick = (item) => {
    const id = _.get(item, 'id', item).split('-');
    const type = id[0];
    switch (type) {
      case 'L':
        this.openDrawerForm(() => this.fromBooking(item.booking), BookingForm);
        break;
      case 'A':
        this.openDrawerForm(() => this.fromAvailability(item), BookingForm);
        break;
      case 'G':
        this.openDrawerForm(() => this.fromGroup(item.guid, id[1]), GroupForm);
        break;
      default:
    }
  }

  openDrawerForm = (prepopulationStrategy, container) => {
    const { actions } = this.props;
    actions.startLoading('drawer');
    prepopulationStrategy().then((values) => {
      actions.showDrawer(container, { ...values, updateCalendarView: this.updateCalendarView });
      actions.stopLoading('drawer');
    });
  }

  handleExportDialogState = (unmount) => {
    const { actions, userGuide } = this.props;
    const { dialogOpen } = this.state;
    if (unmount) {
      actions.destroy('ExportFilterWizard');
      actions.removeFilteredProductTimes();
    }
    this.setState({
      dialogOpen: !dialogOpen,
    });
    if (userGuide && userGuide.currentGuide === 'createOffer' && userGuide.step === 0) {
      setTimeout(() => actions.goToStep(1), 500);
    }
  }

  handlePopoverState = (anchorEl = undefined) => {
    const { popoverOpen } = this.state;

    this.setState({ popoverOpen: !popoverOpen });
    if (anchorEl) {
      this.setState({ anchorEl });
    }
  };

  handleTimelineRef = (timeline) => {
    this.timeline = timeline;
  };

  onSkipClick = () => this.setState({ guideActivationPopup: false });

  onBeginClick = () => {
    const { actions } = this.props;

    this.setState({ guideActivationPopup: false });
    setTimeout(() => actions.activateGuide('calendar', true), 1000);
  };

  handlePopoverClose = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  setUpdateFilter = (updateFilter) => () => this.setState({ updateFilter });

  render() {
    const {
      drawer,
      user,
      events,
      openDialog,
    } = this.props;
    const {
      dialogOpen,
      popoverOpen,
      anchorEl,
      popoverProps,
      guideActivationPopup,
      guideFinishPopup,
      loading,
      instructors,
      updateFilter,
    } = this.state;
    if (!drawer) {
      return null;
    }

    return (
      <div className={`calendar calendar--adjusted ${drawer.open ? 'calendar--reduced' : ''}`}>
        <div className="calendar__top">
          <div className="calendar__user-navigation">
            <ProfileNav />
          </div>
        </div>
        <Filter
          onUpdate={this.onFilterUpdate}
          onInstructorUpdate={this.onInstructorFilterUpdate}
          updateFilter={updateFilter}
          setUpdateFilter={this.setUpdateFilter(false)}
          updateCalendarView={this.updateCalendarView}
        />
        <Dialog
          contentStyle={{ width: '45%', minWidth: '500px', minHeight: '480px' }}
          open={dialogOpen}
          className="export-wizard-box"
        >
          <ExportFilterWizard handleModalState={() => this.handleExportDialogState(true)} />
        </Dialog>
        <EntityAmountDisplay entity="bookings" hide />
        <EntityAmountDisplay entity="instructors" hide />
        <div className="calendar__content">
          <div className="calendar__row-wrapper">
            <div className="buttons">
              <AddEntityButton
                label="Add booking"
                link={false}
                onClick={this.onAddBookingButtonClick}
              />
              <AddEntityButton
                className="add-entity-button__theme--green"
                label="Add group"
                link={false}
                onClick={this.onAddGroupButtonClick}
              />
              <AddEntityButton
                className="add-entity-button__theme--red"
                label="Create offers"
                link={false}
                onClick={this.handleExportDialogState}
              />
              <TodaysCalendarButton
                onClick={this.onTodaysCalendarButtonClick}
              />
              <RefreshIndicator
                size={50}
                top={150}
                left={540}
                status={loading ? 'loading' : 'hide'}
                className={loading ? 'indicator-shown indicator-shown__calendar' : 'indicator-hidden'}
              />
            </div>
            {guideActivationPopup && (
              <GuideActivationPopup
                onSkipClick={this.onSkipClick}
                onBeginClick={this.onBeginClick}
              />
            )}
            {guideFinishPopup && (
              <GuideFinishPopup
                onFinishClick={() => this.setState({ guideFinishPopup: false })}
              />
            )}
            <div id="timeline-header-anchor" />
            <Timeline
              handleTimelineRef={this.handleTimelineRef}
              events={events}
              instructors={_.filter(instructors, (instructor) => instructor.active)}
              onItemUpdate={this.onItemUpdate}
              onGroupOpen={this.onGroupOpen}
              onInstructorClick={this.onInstructorClick}
              onItemDoubleClick={this.onItemDoubleClick}
              onItemContextMenu={this.onItemContextMenu}
              openDialog={openDialog}
              role={user.role}
            />
            <div id="reserved-bookings-anchor" />
            <Popover
              open={popoverOpen}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              onRequestClose={this.handlePopoverClose}
            >
              <CalendarItemPopover {...popoverProps} />
            </Popover>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  instructors: state.entities.instructor,
  group: state.entities.group,
  groupPrice: state.entities.groupPrice,
  events: state.events,
  booking: state.entities.booking,
  lessonBlock: state.entities.lessonBlock,
  resort: state.entities.resort,
  account: state.entities.account,
  activity: state.entities.activity,
  speciality: state.entities.speciality,
  userGuide: state.userGuide,
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    showDrawer,
    hideDrawer,
    fetchInstructors,
    fetchEvents,
    updateEvent,
    removeEvent,
    patchEvent,
    fetchBooking,
    clear,
    destroy,
    removeFilteredProductTimes,
    fetchPartnerManagers,
    fetchClients,
    activateGuide,
    deactivateGuide,
    removeTimelineBoundsChange,
    goToStep,
    fetchBookingsUsedAmount,
    removeCalendarScrollIntoView,
    fetchGroupsForBookings,
    startLoading,
    stopLoading,
    fetchGroupPrice,
    fetchGroupByGuid,
  }, dispatch),
});

CalendarPage.propTypes = {
  actions: PropTypes.shape({
    showDrawer: PropTypes.func,
    hideDrawer: PropTypes.func,
    fetchInstructors: PropTypes.func,
    fetchEvents: PropTypes.func,
    updateEvent: PropTypes.func,
    removeEvent: PropTypes.func,
    patchEvent: PropTypes.func,
    fetchBooking: PropTypes.func,
    clear: PropTypes.func,
    destroy: PropTypes.func,
    removeFilteredProductTimes: PropTypes.func,
    fetchPartnerManagers: PropTypes.func,
    fetchClients: PropTypes.func,
    activateGuide: PropTypes.func,
    deactivateGuide: PropTypes.func,
    removeTimelineBoundsChange: PropTypes.func,
    goToStep: PropTypes.func,
    fetchBookingsUsedAmount: PropTypes.func,
    removeCalendarScrollIntoView: PropTypes.func,
    fetchGroupsForBookings: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
    fetchGroupPrice: PropTypes.func,
    fetchGroupByGuid: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.object,
  userGuide: PropTypes.shape({
    triggerTimelineBoundsChange: PropTypes.shape({
      timeFrom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      timeTo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      group: PropTypes.string,
    }),
    calendarScrollIntoView: PropTypes.string,
    triggerGroupFormOpen: PropTypes.bool,
    triggerBookingFormOpen: PropTypes.bool,
    triggerCreateOffersOpen: PropTypes.bool,
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.object,
  openDialog: PropTypes.func,
  drawer: PropTypes.shape({
    open: PropTypes.bool,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  group: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
