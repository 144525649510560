import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select, { Creatable } from 'react-select';
import {
  reduxForm,
  formValueSelector,
  Field,
  SubmissionError,
} from 'redux-form';
import {
  map,
  omit,
  forEach,
  get,
  isEmpty, find,
} from 'lodash';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import * as instructorsActions from '../actions/instructorsActions';
import * as specialitiesActions from '../actions/specialityActions';
import * as activityActions from '../actions/activitiesActions';
import { notifySuccess, notifyError } from '../actions/notificationActions';
import { fetchServices } from '../actions/serviceActions';

import ActivityTable from '../components/ActivityTable';
import InstructorTab from '../components/InstructorTab';
import RenderFieldLanguages from '../components/RenderFieldLanguages';
import RenderField from '../components/RenderField';
import EntityForm from '../components/EntityForm';
import {
  formatLanguagesLevelsValues,
  formatPhoneNumbersArray,
  formatDateOfBirth,
  parseReactSelectValues,
} from '../utils/helpers';
import { maxLength255 } from '../utils/validators';
import languageLevelsOptions from '../constants/LanguageLevelsOptions';
import { languageOptions } from '../constants/LanguageOptions';
import countryList from '../constants/CountryList';
import '../styles/ProductAdd.scss';

class InstructorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletedLanguages: [],
      languages: [{}],
      activities: {
        '': [{ '': '' }],
      },
      ready: !props.match.params.id,
    };
  }

  componentDidMount() {
    const { user, match, actions } = this.props;
    const id = user.role === 'manager' ? match.params.id : user.id;
    Promise.all([
      id && actions.instructors.fetchInstructor(id),
      actions.specialities.fetchSpecialities(),
      actions.activities.fetchActivities(),
      actions.fetchServices(),
    ]).then((res) => {
      map(res, (resEntity) => {
        if (resEntity && resEntity.type === 'INSTRUCTOR_GET_SUCCESS') {
          this.parseInitialLanguagesAndActivities(resEntity, id);
        }
        this.setState({
          ready: true,
        });
      });
    });
  }

  onSubmit = (values) => {
    const {
      selector,
      match,
      user,
      actions,
      history,
    } = this.props;
    const { deletedLanguages, deletedPhoneNumbers } = this.state;
    const id = user.role === 'manager' ? match.params.id : user.id;
    const { activities } = selector;

    forEach(activities, (activity, activityKey) => {
      const tempActivity = activity;
      forEach(activity, (spec, specKey) => {
        if (!spec) {
          delete tempActivity[specKey];
        }
      });
      if (isEmpty(activity)) {
        delete activities[activityKey];
      }
    });

    const languages = formatLanguagesLevelsValues(values.languages, deletedLanguages);
    const phoneNumbers = formatPhoneNumbersArray(values.phoneNumbers, deletedPhoneNumbers);
    const dateOfBirth = formatDateOfBirth(values.dateOfBirth);
    const additionals = parseReactSelectValues(values.additionals);
    const workPermissions = parseReactSelectValues(values.workPermissions);
    const nationality = values.nationality ? values.nationality : null;
    let updatedValues = omit(values, 'activities');

    if (!isEmpty(activities)) {
      updatedValues = {
        ...updatedValues,
        activities,
      };
    } else {
      updatedValues.activities = {};
    }
    const instructor = {
      ...updatedValues,
      languages,
      phoneNumbers,
      dateOfBirth,
      additionals,
      workPermissions,
      nationality,
    };

    if (activities && Object.prototype.hasOwnProperty.call(activities, '')) {
      actions.notifyError({}, 'Please specify activity name');
    } else if (dateOfBirth === 'Invalid date') {
      throw new SubmissionError({ dateOfBirth: 'This value is not a valid date' });
    } else if (!id) {
      actions.instructors.createInstructor(instructor)
        .then(() => {
          history.push('/instructors');
          actions.notifySuccess({}, 'Instructor created successfully');
        }).catch((err) => {
          this.handleError(err);
        });
    } else if (user.role === 'instructor' || user.role === 'headCoach') {
      actions.instructors.updateAccount(id, instructor)
        .then(() => {
          actions.notifySuccess({}, 'Account updated successfully');
        }).catch((err) => {
          this.handleError(err);
        });
    } else if (user.role === 'manager') {
      actions.instructors.updateInstructor(id, instructor)
        .then(() => {
          history.push('/instructors');
          actions.notifySuccess({}, 'Instructor updated successfully');
        }).catch((err) => {
          this.handleError(err);
        });
    }
  }

  parseInitialLanguagesAndActivities = (response, id) => {
    const instructor = response.payload.result.instructor[id];
    this.setState({
      languages: instructor.languages && instructor.languages.length ? instructor.languages : [{}],
      activities: instructor.activities ? instructor.activities : {
        '': [{ '': '' }],
      },
    });
  }

  setActivity = (activity, oldValue) => {
    const { selector, change } = this.props;
    const { activities: stateActivities } = this.state;
    const activities = selector.activities || {
      '': [{ '': '' }],
    };
    let replacedActivities = stateActivities;
    if (Object.prototype.hasOwnProperty.call(stateActivities, activity)) {
      return;
    }

    replacedActivities = {};
    forEach(Object.keys(activities), (key) => {
      let tempKey = key;
      let activityData = activities[tempKey];
      if (tempKey === oldValue) {
        activityData = { Standard: 'First steps' };
        tempKey = activity;
      }
      replacedActivities[tempKey] = activityData;
    });

    this.setState({
      activities: replacedActivities,
      curActivityName: activity,
    });
    change('activities', replacedActivities);
  }

  removeTable = (activity) => {
    const { activities } = this.state;
    const { selector, change } = this.props;
    delete activities[activity];
    const activitiesChanged = omit(selector.activities, activity);
    change('activities', activitiesChanged);
    this.setState({ activities });
  }

  handleMinusButton = (option, id) => {
    const { deletedLanguages } = this.state;

    deletedLanguages.push(id);
    this.setState({
      deletedLanguages,
    });
  }

  handleAddButton = (option) => {
    const { selector, change } = this.props;
    const { languages } = this.state;
    switch (option) {
      case 'activities': {
        const activities = Array.isArray(selector.activities) ? {} : selector.activities;
        activities[''] = { activity: '' };
        this.setState({ activities });
        change('activities', activities);
        break;
      }
      case 'language': {
        const tempLanguages = languages || [];
        tempLanguages.push({});
        this.setState({
          languages: tempLanguages,
        });
        break;
      }
      default:
        break;
    }
  }

  handleRedirect = () => {
    const { history } = this.props;
    history.push('/availability');
  }

  handleSelectChange = (fieldName, value) => {
    const { change } = this.props;
    let val = value;
    if (fieldName === 'month') {
      val = val[0] + val[1];
    }
    change(fieldName, val);
  }

  formatAdditionalsOptions = (additionals) => map(additionals,
    (additional) => ({ value: additional.name, label: additional.name }));

  handleError = (err) => {
    const property = get(err, 'data.constraintViolations[0].property', '');
    const message = get(err, 'data.constraintViolations[0].message', '');

    if (property === 'languages[level]' || property === 'languages[code]') {
      throw new SubmissionError({ languages: message });
    }
    if (property.indexOf('phoneNumbers') === 0) {
      throw new SubmissionError({ phoneNumbers: message });
    }
    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  };

  generateMultiValues = (values, options) => map(values, (value) => {
    if (value.value) {
      return value;
    }
    return find(options, (option) => option.value === value);
  });

  render() {
    const {
      handleSubmit,
      selector,
      activityList,
      specialities,
      match,
      submitting,
      error,
      invalid,
      user,
      additionalServices,
    } = this.props;
    const {
      activities,
      ready,
      languages,
      deletedLanguages,
      curActivityName,
    } = this.state;
    const additionalsOptions = additionalServices
      ? this.formatAdditionalsOptions(additionalServices) : [];
    if (!ready) {
      return null;
    }
    return (
      <div>
        <EntityForm
          error={error}
          submitting={submitting}
          formDestination="Instructor"
          handleSubmit={handleSubmit(this.onSubmit)}
          editing={!!match.params.id}
          handleRedirect={this.handleRedirect}
          invalid={invalid}
          user={user}
        >
          <div className="client-manager-form profile">
            <form
              onSubmit={handleSubmit(this.onSubmit)}
              id="instructor-form"
            >
              <InstructorTab
                {...this.props}
                setSelectorValue={this.handleSelectChange}
                nationalities={countryList}
              />
              <div className="page__title--spacing">
                <span className="page__header">
                  WORK INFO
                </span>
              </div>

              <div className="client-manager-form">
                <div className="client-manager-form__main-info">
                  <div className="client-manager-form__field-group">
                    <Field
                      name="languages"
                      component={RenderFieldLanguages}
                      languages={[...languages]}
                      deletedLanguages={[...deletedLanguages]}
                      selector={selector}
                      languageOptions={languageOptions}
                      languageLevelsOptions={languageLevelsOptions}
                      handleSelectChange={this.handleSelectChange}
                      handleMinusButton={this.handleMinusButton}
                      handleAddButton={this.handleAddButton}
                    />
                    <div className="client-manager-form__input-wrapper" style={{ marginTop: '17px' }}>
                      <label className="client-manager-form__label">WORK PERMISSION</label>
                      <Select
                        className="w250"
                        multi
                        name="workPermissions"
                        value={this.generateMultiValues(selector.workPermissions, countryList)}
                        onChange={(value) => this.handleSelectChange('workPermissions', value)}
                        options={countryList}
                        clearable
                      />
                    </div>
                  </div>
                  <div className="client-manager-form__field-group">
                    <div className="client-manager-form__input-wrapper">
                      <label className="client-manager-form__label">ADDITIONALS</label>
                      <Creatable
                        name="additionals"
                        className="w377"
                        multi
                        clearable
                        component={RenderField}
                        value={this.generateMultiValues(selector.additionals, additionalsOptions)}
                        options={additionalsOptions}
                        validate={[maxLength255]}
                        onChange={(value) => this.handleSelectChange('additionals', value)}
                      />
                    </div>
                  </div>
                  <div className="client-manager-form__field-group">
                    <label className="client-manager-form__label">ACTIVITY / LEVEL</label>
                    {activities && map(activities, ((key, activity) => (
                      <ActivityTable
                        key={activity}
                        activity={activity}
                        activities={activities}
                        curActivityName={curActivityName}
                        specialities={specialities}
                        activityList={activityList}
                        setActivity={this.setActivity}
                        onActivityDelete={() => this.removeTable(activity)}
                      />
                    )
                    ))}
                    <FloatingActionButton
                      className="client-manager-form__plus-button"
                      backgroundColor="#01579B"
                      type="button"
                      mini
                      onClick={() => this.handleAddButton('activities')}
                    >
                      <ContentAdd id="addActivity" />
                    </FloatingActionButton>
                    <label className="select-another-activity">Select another activity</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </EntityForm>
      </div>
    );
  }
}

const formConfig = {
  form: 'InstructorPage',
  enableReinitialize: true,
};

const selector = formValueSelector('InstructorPage');

const mapDispatchToProps = (dispatch) => ({
  actions: {
    instructors: bindActionCreators(instructorsActions, dispatch),
    specialities: bindActionCreators(specialitiesActions, dispatch),
    activities: bindActionCreators(activityActions, dispatch),
    fetchServices: bindActionCreators(fetchServices, dispatch),
    notifySuccess: bindActionCreators(notifySuccess, dispatch),
    notifyError: bindActionCreators(notifyError, dispatch),
  },
});

const mapStateToProps = (state, props) => {
  const id = state.user.role === 'manager' ? props.match.params.id : state.user.id;
  let initialValues = {
    phoneNumbers: [''],
  };
  let activityList = [];
  if (id) {
    const instructor = state.entities.instructor[id];
    const dateOfBirth = instructor && new Date(instructor.dateOfBirth);
    initialValues = {
      ...instructor,
      dateOfBirth,
    };
  }
  if (Object.keys(state.entities.activity).length) {
    activityList = map(state.entities.activity, (it) => ({
      value: it.name,
      label: it.name,
      id: it.id,
    }));
  }
  return {
    initialValues,
    activityList,
    selector: selector(state, 'additionals', 'languages', 'workPermissions', 'gender', 'nationality', 'phoneNumbers', 'currentAddress', 'month', 'activities'),
    specialities: state.entities.speciality,
    additionalServices: state.entities.service,
    user: state.user,
  };
};

InstructorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }),
  actions: PropTypes.shape({
    notifyError: PropTypes.func,
    notifySuccess: PropTypes.func,
    instructors: PropTypes.shape({
      fetchInstructor: PropTypes.func,
      createInstructor: PropTypes.func,
      updateInstructor: PropTypes.func,
      updateAccount: PropTypes.func,
    }),
    specialities: PropTypes.shape({
      fetchSpecialities: PropTypes.func,
    }),
    activities: PropTypes.shape({
      fetchActivities: PropTypes.func,
    }),
    fetchServices: PropTypes.func.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  activityList: PropTypes.array,
  selector: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    activities: PropTypes.object,
    workPermissions: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
    // eslint-disable-next-line react/forbid-prop-types
    additionals: PropTypes.array,
  }),
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  specialities: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  initialValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    additionals: PropTypes.object,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  additionalServices: PropTypes.object,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(InstructorPage)));
