let invokeUrl;
switch (process.env.API_ENV) {
  case 'production':
    // invokeUrl = 'https://api.skicms.com';
    invokeUrl = 'https://api.lefik.eu';
    break;
  case 'development':
    // invokeUrl = 'https://api-test.skicms.com';
    invokeUrl = 'https://api.lefik.eu';
    break;
  default:
    // invokeUrl = 'https://api.skicms.com';
    invokeUrl = 'https://api.lefik.eu';
}
module.exports = {
  invokeUrl,
};
