import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CookieConsent from 'react-cookie-consent';
import AnonymousNav from './AnonymousNav';
import Notification from './Notification';
import FullscreenAgreementsDialog from './FullscreenAgreementsDialog';
import cookiePolicy from '../agreements/cookiePolicy.pdf';
import '../styles/App.scss';

const locationPathnames = [
  '/login',
  '/register',
  '/changePassword',
  '/success',
  '/error',
];

class Site extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      pageNumber: 1,
      numPages: null,
    };
  }

  handleModalOpenState = () => {
    const { modalOpened } = this.state;
    this.setState({
      modalOpened: !modalOpened,
    });
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  }

  setPageNumber = (pageNo) => {
    this.setState({
      pageNumber: pageNo,
    });
  }

  render() {
    const { modalOpened, pageNumber, numPages } = this.state;
    const { children } = this.props;
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname.indexOf('lefik') > 0 && !locationPathnames.includes(location.pathname)) {
      window.location = 'login';
      // eslint-disable-next-line no-restricted-globals
    } else if (location.hostname.indexOf('lefik') === 0 && !locationPathnames.includes(location.pathname)) {
      window.location = 'register';
    }

    const className = classNames(
      'anonymous-access',
      {
        'success-page': (window.location.pathname === '/success' || window.location.pathname === '/error'),
      },
    );

    return (
      <div className={className}>
        <Notification />
        <AnonymousNav />
        {children && React.cloneElement(children, this.props)}
        {
          modalOpened
            ? (
              <FullscreenAgreementsDialog
                open={modalOpened}
                pageNumber={pageNumber}
                numPages={numPages}
                handleAgreementsState={this.handleModalOpenState}
                onDocumentLoad={this.onDocumentLoad}
                title="Cookie Policy"
                file={cookiePolicy}
                setPageNumber={this.setPageNumber}
              />
            )
            : (
              <CookieConsent
                containerClasses="cookie-footer"
                contentClasses="cookie-footer--content"
              >
                We use cookies to give you the best experience on site.
                If you want to continue using site please click and agree with our&nbsp;
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={this.handleModalOpenState}
                >
                  cookie policy
                </a>
                .
              </CookieConsent>
            )
        }
      </div>
    );
  }
}

Site.propTypes = {
  children: PropTypes.element,
};

export default Site;
