import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Checkbox } from 'redux-form-material-ui';
import u from 'underscore';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import _ from 'lodash';
import { fetchService, fetchServices } from '../actions/serviceActions';
import { Select } from './Field';
import AddLessonButton from './AddLessonButton';
import RemoveLessonButton from './RemoveLessonButton';
import RenderSelect from './RenderSelect';
import RenderField from './RenderField';
import RenderToggle from './RenderToggle';
import { required } from '../utils/validators';
import { mapPropsToOptions } from '../utils/map';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import { isEmptyObject } from '../utils/helpers';
import { resolveEntity } from '../utils/formHelpers';

const RenderBookingServices = (props) => {
  const {
    valid,
    preopen,
    service,
    fields,
    onChange,
    fetchServicesA,
    fetchServiceA,
    change,
    disabled,
  } = props;

  const [services, setServices] = useState([]);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const currentFields = useRef({
    getAll: () => {},
  });

  useEffect(() => {
    fetchServicesA();
  }, []);

  useEffect(() => {
    setServices(mapPropsToOptions(service));
  }, [service]);

  useEffect(() => {
    if (!u.isMatch(fields.getAll(), currentFields.current.getAll()) && valid) {
      onChange();
    }
    currentFields.current = fields;
  }, [fields, valid]);

  const handleChange = (index, field, value) => {
    change(`bookingServices[${index}].${field}`, value);
    onChange();
  };

  const onServiceChange = (index, e, value) => {
    setCheckboxDisabled(false);
    resolveEntity(props, 'service', value, fetchServiceA).then((s) => {
      handleChange(index, 'service', value);
      handleChange(index, 'price', s.price);
    });
  };

  useEffect(() => {
    if (!isEmptyObject(service) && preopen && !fields.get(0)) {
      const firstKey = Object.keys(service)[0];
      onServiceChange(0, undefined, firstKey);
    }
  }, [service, preopen, fields]);

  const onDiscountChange = (index, e, value) => {
    const i = value.indexOf('%');
    const discount = value.substr(0, i);

    handleChange(index, 'discount', i === -1 ? value : discount || 0);
    handleChange(index, 'discountType', i === -1 ? 'fixed' : 'percent');
  };

  const handleVatChange = (bookingService) => (isInputChecked) => {
    change(`${bookingService}.vat`, isInputChecked);
    onChange();
  };

  const onRemove = (index) => {
    fields.remove(index);
    onChange();
  };

  const changeCheckboxState = (val) => setCheckboxDisabled(val);

  return (
    <div className="BookingForm__multiple-services">
      {fields.map((bookingService, index) => (
        <div className="BookingForm__service" key={index}>
          {!disabled && (
            <div className="BookingForm__service-delete-button">
              <RemoveLessonButton onClick={() => onRemove(index)} />
            </div>
          )}
          <div className="service--form">
            <Field
              component={RenderToggle}
              name={`${bookingService}.paid`}
              disabled={disabled}
              onChange={(e, v) => change(`${bookingService}.paid`, v)}
              value={fields.get(`[${index}].paid`)}
              normalize={(v) => (_.isBoolean(v) ? v : false)}
              wrapperClass="services"
            />
            <span id={`additional-service-${index}-anchor`} />
            <Select
              simpleValue
              fieldLabel="Service:"
              name={`${bookingService}.service`}
              component={RenderSelect}
              options={services}
              onChange={(e, value) => onServiceChange(index, e, value)}
              onOpen={() => changeCheckboxState(true)}
              onValueClick={() => changeCheckboxState(true)}
              onBlur={() => changeCheckboxState(false)}
              theme="white"
              disabled={disabled}
            />
            <div className="form-field">
              <div className="form-label__white">Price with vat:</div>
              <div className="service-checkbox">
                <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
                  <Field
                    name={`${bookingService}.vat`}
                    component={Checkbox}
                    onCheck={handleVatChange(bookingService)}
                    disabled={checkboxDisabled || disabled}
                  />
                </MuiThemeProvider>
              </div>
            </div>
            <div className="BookingForm__service-inline-inputs">
              <Field
                name={`${bookingService}.price`}
                component={RenderField}
                type="number"
                validate={[required]}
                label="Price:"
                labelClass="BookingForm__service-inline-label"
                onChange={(e, v) => {
                  change(`${bookingService}.price`, v);
                  onChange();
                }}
                theme="white"
                disabled={disabled}
              />
              <Field
                name={`${bookingService}.discount`}
                component={RenderField}
                type="text"
                label="Discount:"
                labelClass="BookingForm__service-inline-label"
                onChange={(e, value) => onDiscountChange(index, e, value)}
                theme="white"
                disabled={disabled}
              />
            </div>
          </div>
          <div className="BookingForm__service-add-button">
            {!disabled && <AddLessonButton style={{ cursor: 'pointer' }} onClick={() => fields.push({})} />}
          </div>
        </div>
      ))}
    </div>
  );
};

RenderBookingServices.propTypes = {
  fetchServiceA: PropTypes.func,
  fetchServicesA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  service: PropTypes.object,
  fields: PropTypes.shape({
    push: PropTypes.func,
    getAll: PropTypes.func,
    get: PropTypes.func,
    remove: PropTypes.func,
    map: PropTypes.func,
  }),
  preopen: PropTypes.bool,
  valid: PropTypes.bool,
  onChange: PropTypes.func,
  change: PropTypes.func,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  account: state.entities.account,
  service: state.entities.service,
});

const mapDispatchToProps = (dispatch) => ({
  fetchServiceA: bindActionCreators(fetchService, dispatch),
  fetchServicesA: bindActionCreators(fetchServices, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RenderBookingServices));
