import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  filter,
  forEach,
  get,
  includes,
  map,
  toLower,
  isEqual,
} from 'lodash';

import Checkbox from 'material-ui/Checkbox';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';

import EditEntityButton from '../components/EditEntityButton';
import ListTable from '../components/ListTable';
import DeleteEntityButton from '../components/DeleteEntityButton';
import PartnerForm from './PartnerForm';
import AddEntityButton from '../components/AddEntityButton';
import MassActionsComponent from '../components/MassActionsComponent';
import Filter from '../components/FilterComponent';
import UploadModal from './UploadModal/UploadModal';
import Dialog from '../components/dialog/Dialog';

import * as partnerActions from '../actions/partnerActions';
import { goToStep } from '../actions/userGuide';
import { notifyError, notifySuccess } from '../actions/notificationActions';

import { sortTable } from '../utils/helpers';

import '../styles/InstructorList.scss';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import { downloadCsvFile } from '../utils/csvHelper';

const customContentStyle = {
  width: '450px',
};

class PartnerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpenCommission: false,
      dialogOpenPartner: false,
      partnerIdEditing: null,
      partners: {},
      selected: [],
      searchValues: [],
      massActions: [
        { text: 'Delete', value: 1 },
      ],
      uploadModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      actions: {
        partners,
        indicatorActions: { startLoading, stopLoading },
      },
    } = this.props;

    startLoading('partners');
    Promise.all([
      partners.fetchPartners(),
    ]).then(() => {
      stopLoading('partners');
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { partners } = props;
    const { searchValues: stateSearchValues } = state;
    const searchValues = map(partners, (p) => ({ value: p, text: p.name }));
    if (!isEqual(searchValues, stateSearchValues)) {
      return {
        ...state,
        partners,
        searchValues,
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { userGuide } = this.props;
    const { userGuide: userGuidePrev } = prevProps;

    if (userGuide && userGuide.triggerPartnerModalOpen
     && !userGuidePrev.triggerPartnerModalOpen) {
      this.handleDialogPartner();
    }
  }

  partnersToRows = (partners) => {
    const { openDialog } = this.props;

    return map(partners, (partner) => {
      const row = [];
      const selected = this.getCheckboxValue(partner.id);

      row[0] = (
        <Checkbox
          checked={selected}
          onCheck={() => this.handleCheck(partner.id)}
        />
      );
      row[1] = partner.name
        ? (
          <span onClick={this.handleEditButton(partner.id)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {partner.name}
          </span>
        )
        : '-';
      row[2] = '-';
      if (partner.address) {
        if (partner.address.street) {
          row[2] = partner.address.street;
        }
      }
      row[3] = (
        <div>
          <span>{partner.commission}</span>
          <span>%</span>
        </div>
      );
      row[4] = (
        <div>
          <span onClick={this.handleEditButton(partner.id)}>
            <EditEntityButton />
          </span>
          <span>
            <DeleteEntityButton
              id={partner.id}
              handleDelete={() => openDialog(() => this.handleDelete(partner.id))}
            />
          </span>
        </div>
      );
      return row;
    });
  }

  handleEditButton = (id) => () => {
    const { dialogOpenCommission } = this.state;
    this.setState({
      dialogOpenCommission: !dialogOpenCommission,
      partnerIdEditing: id,
    });
  }

  handleDelete = (id) => {
    const { selected } = this.state;
    const { actions } = this.props;

    if (id === '*') {
      return actions.partners.deletePartners({ ids: selected }).then((res) => (
        this.handleMultipleDeletionResponse(res)
      ));
    }

    return actions.partners.deletePartner(id).then(() => {
      actions.notifySuccessA({}, 'Partner deleted successfully');
    });
  };

  handleMultipleDeletionResponse = (res) => {
    const { actions } = this.props;
    const discarded = get(res, 'payload.result.discarded', []);

    if (discarded.length) {
      return actions.notifyErrorA({}, 'Some of the partners could not be deleted because of the relations to bookings');
    }

    return actions.notifySuccessA({}, 'All partners deleted successfully');
  };

  handleDialogCommission = (id) => {
    const { dialogOpenCommission } = this.state;
    this.setState({
      dialogOpenCommission: !dialogOpenCommission,
      partnerIdEditing: id || null,
    });
  };

  handleDialogPartner = () => {
    const { userGuide, actions: { goToStepA } } = this.props;
    const { dialogOpenPartner } = this.state;

    this.setState({
      dialogOpenPartner: !dialogOpenPartner,
    });

    if (userGuide && userGuide.step === 2) {
      goToStepA(3);
    }
  };

  handleCommissionUpdate = (values) => {
    const { actions } = this.props;
    const { partnerIdEditing } = this.state;
    const partner = {
      ...values,
      commission: parseFloat(values.commission),
      commissionType: 'percent',
    };
    delete partner.account;
    actions.partners.updatePartner(partner, partnerIdEditing).then(() => {
      actions.notifySuccessA({}, 'Partner updated successfully');
      this.handleDialogCommission();
    });
  }

  handleCreatePartner = (values) => {
    const { actions } = this.props;
    const partner = {
      ...values,
      commission: parseFloat(values.commission),
      commissionType: 'percent',
    };
    delete partner.account;
    return actions.partners.createPartner(partner).then(() => {
      actions.notifySuccessA({}, 'Partner created successfully');
      this.handleDialogPartner();
    }).catch((err) => err);
  }

  handleAddButton = () => this.handleDialogPartner();

  handleSortTable = (columnIndex, asc) => {
    const { partners } = this.state;
    const columns = {
      1: {
        name: ['name'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      2: {
        name: ['address.street'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      3: {
        name: ['commission'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
    };
    const sortedList = sortTable(partners, columns, columnIndex, asc);
    this.setState({
      partners: sortedList,
    });
  }

  handleCheck = (id) => {
    if (id === '*') {
      this.handleMultiCheck();
    } else {
      this.handleSingleCheck(id);
    }
  };

  handleMultiCheck = () => {
    const { selected, partners } = this.state;
    const tempSelected = [];

    if (!selected.length) {
      forEach(partners, (p) => tempSelected.push(p.id));
    }

    this.setState({ selected: tempSelected });
  };

  handleSingleCheck = (id) => {
    const { selected } = this.state;
    let tempSelected = selected;

    if (includes(selected, id)) {
      tempSelected = filter(selected, (s) => s !== id);
    } else {
      tempSelected.push(id);
    }

    this.setState({ selected: tempSelected });
  };

  getCheckboxValue = (id) => {
    const { selected, partners } = this.state;

    if (id === '*') {
      return Object.keys(partners).length === selected.length;
    }
    return includes(selected, id);
  };

  setMassActionType = () => this.setState((state) => ({ ...state }));

  handleSearchAutocomplete = (requestList, inputValue) => {
    const { partners } = this.props;

    const filtered = filter(partners, (p) => includes(toLower(p.name), toLower(inputValue)));

    this.setState({ partners: filtered });
  };

  handleUploadModalState = () => {
    const { uploadModalOpen } = this.state;
    this.setState({ uploadModalOpen: !uploadModalOpen });
  }

  handleExportClick = () => {
    const { actions: { partners: { partnersExport } } } = this.props;
    partnersExport().then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'partners.csv');
    });
  };

  render() {
    const {
      dialogOpenCommission,
      dialogOpenPartner,
      partnerIdEditing,
      partners,
      massActions,
      searchValues,
      uploadModalOpen,
    } = this.state;
    const { openDialog, refreshIndicator, actions } = this.props;
    const selectedAll = this.getCheckboxValue('*');

    return (
      <div className="booking-list">
        <div className="group-list__row-wrapper">
          <AddEntityButton
            label="ADD PARTNER"
            onClick={this.handleAddButton}
          />
          <AddEntityButton
            label="UPLOAD"
            className="add-entity-button__theme--green"
            onClick={this.handleUploadModalState}
          />
          <AddEntityButton
            label="EXPORT"
            className="add-entity-button__theme--pink"
            onClick={this.handleExportClick}
          />
          <MassActionsComponent
            massActions={massActions}
            detectMassAction={this.setMassActionType}
            handleMassAction={() => openDialog(() => this.handleDelete('*'))}
          />
          <Filter
            destination="Partners"
            dataSource={searchValues}
            onUpdateInput={this.handleSearchAutocomplete}
          />
        </div>
        <UploadModal
          open={uploadModalOpen}
          handleUploadModalState={this.handleUploadModalState}
          uploadFn={actions.partners.partnersImport}
          onSuccessCallback={actions.partners.fetchPartners}
          templateName="partners.csv"
          templateLocation="https://s3-eu-west-1.amazonaws.com/skicms-csv/partners.csv"
        />
        <ListTable
          className={!refreshIndicator.partners.cached ? 'indicator-hidden' : 'partner-table'}
          dest="Partner"
          names={['checkbox', 'partner', 'address', 'commission', 'icons']}
          header={[
            <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
              <Checkbox ref="multiCheck" onCheck={() => this.handleCheck('*')} checked={selectedAll} />
            </MuiThemeProvider>,
            'Partner',
            'Address',
            'Commission',
            ' ',
          ]}
          style={{ width: '100%', minWidth: '480px' }}
          rows={this.partnersToRows(partners)}
          handleSort={this.handleSortTable}
          small
        />
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.partners.cached ? 'hide' : 'loading'}
          className={refreshIndicator.partners.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
        <Dialog
          modal={false}
          open={dialogOpenCommission}
          onRequestClose={this.handleDialogCommission}
          contentStyle={customContentStyle}
          className="partner-box"
        >
          <PartnerForm
            handleCreatePartner={this.handleCommissionUpdate}
            handleClose={this.handleDialogCommission}
            initialValues={partners[partnerIdEditing]}
          />
        </Dialog>
        <Dialog
          modal={false}
          open={dialogOpenPartner}
          onRequestClose={this.handleDialogPartner}
          contentStyle={customContentStyle}
          className="partner-box"
        >
          <PartnerForm
            handleCreatePartner={this.handleCreatePartner}
            handleClose={this.handleDialogPartner}
            initialValues={null}
          />
        </Dialog>
      </div>
    );
  }
}

PartnerList.propTypes = {
  actions: PropTypes.shape({
    notifySuccessA: PropTypes.func,
    notifyErrorA: PropTypes.func,
    goToStepA: PropTypes.func,
    partners: PropTypes.shape({
      fetchPartners: PropTypes.func,
      deletePartners: PropTypes.func,
      deletePartner: PropTypes.func,
      updatePartner: PropTypes.func,
      createPartner: PropTypes.func,
      partnersExport: PropTypes.func,
      partnersImport: PropTypes.func,
    }),
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
  }),
  userGuide: PropTypes.shape({
    triggerPartnerModalOpen: PropTypes.bool,
    step: PropTypes.number,
  }),
  partners: PropTypes.shape({
    name: PropTypes.string,
  }),
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    partners: PropTypes.shape({
      cached: PropTypes.bool,
    }),
  }),
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    partners: bindActionCreators(partnerActions, dispatch),
    notifySuccessA: bindActionCreators(notifySuccess, dispatch),
    notifyErrorA: bindActionCreators(notifyError, dispatch),
    goToStepA: bindActionCreators(goToStep, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
  },
});

const mapStateToProps = (state) => ({
  partners: state.entities.partner,
  userGuide: state.userGuide,
  refreshIndicator: state.refreshIndicator,
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerList);
